import React, {useEffect, useState} from 'react';
import Header from '../components/site/Header';
import {connect, useSelector} from "react-redux";
import Match from "../components/home/Match";
import data from '../api/ornek.json';
import Leftnav from "../components/site/Leftnav";
import Footer from "../components/home/Footer";
import RightNav from "../components/site/RightNav";
import axios from "axios";
import DetailLeftnav from "../components/detail/DetailLeftNav";
import Coupon from "../components/detail/Coupon";
import {useParams} from "react-router-dom";
import Oddetail from "../components/detail/Oddetail";
import Oddetailothers from "../components/detail/Oddetailothers";
function Matchdetails (currentAppLocale) {
     let params = useParams();
    const [match,setMatch] = useState({});
    const [activeOptions,setActiveOptions] = useState({})
    const [mcatalogs,setMcatalogs] = useState([])
    const [oddtype,setOddtype] = useState(0)
    const allData = useSelector((state) => state.general);
    useEffect(() => {
        const getMatches = async () => {
            try {
                let data = {
                    "id":params.id
                }
                const response = await axios.post(
                    process.env.REACT_APP_BACKEND+"/api/general/getmatch",
                    { headers: { 'Content-Type': 'application/json' }, data }
                );
                setMatch(response.data);
                setMcatalogs(response.data[0].marketCatalogues);
                console.log("response.data[0].marketCatalogues",response.data[0].marketCatalogues)
            } catch (error) {
                console.error("Error posting data:", error);
            }
        }
        getMatches();
    },[])
    const adjustMarketcatalogs = (data) => {
        if (data===0) {
            setMcatalogs(match[0].marketCatalogues)
        }
        else {
            let sondata = [];
            sondata.push(data)
            setMcatalogs(sondata);
        }

    };
    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Aylar 0'dan başlıyor, bu yüzden +1 ekliyoruz.
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    return (
        <>
            <div className="main mains homemain">
                <Header lang={currentAppLocale} />
                <div className={"hmpg"}>
                    <div className={"row"}>
                        <div className={"col-md-2 left_hide "}>
                            {match[0] && (
                                <DetailLeftnav adjustMarketcatalogs={adjustMarketcatalogs} setActiveOptions={setActiveOptions} data={match[0]}  lang={currentAppLocale}/>
                            )}

                        </div>
                        <div className={"col-md-7 pr5 col-sm-12 col-xs-12"}>
                            {match[0] && (
                                <div>
                            <div  className="match-item">
                                <div className="match-header">
                                    <a href={"/matchdetails/"+match[0].id} className="match-name">{match[0].name}</a>
                                    <span className="match-date">{match.marketStartTime}</span>
                                </div>
                                <div className={"match-date"}>
                                    <span className="match-name">  {formatDate(new Date(match[0].openDate))}</span>
                                </div>
                                <div className={"match-date"}>
                                    <span className="eslesmis">  Eşleşmiş: {match[0].marketCatalogues[0].totalMatched}</span>
                                </div>

                            </div>
                                    <div className={"row"}>

                                { mcatalogs.length>0 && mcatalogs.map((mtch, index) => (

                                   <>
                                        {mtch.marketName === "Maç Sonucu" ? (
                                                <div className={"col-md-12 mt-20"}>
                                                <Oddetail  matchdata={match[0]} mtch={mtch} index={index}/>
                                            </div>
                                        ):(
                                                <div className={"col-md-6 mb-20"}>
                                                <Oddetailothers  matchdata={match[0]} mtch={mtch} index={index}/>
                                                </div>
                                        )}


                                   </>

                                ))}
                                    </div>



                            </div>
                            )}
                        </div>
                        <div className={"pl0 col-md-3 col-sm-6 right_hide "}>
                            <Coupon activeoptions={activeOptions} lang={currentAppLocale}/>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );

}
const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(Matchdetails);