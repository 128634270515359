import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLeagues} from "../../actions/general";
import {useCookies} from "react-cookie";
import leagues from '../../api/leagues.json';
import countries from '../../api/countries.json';
import {Collapse, List, ListItem, Typography} from "@material-ui/core";
const DetailLeftnav = ({adjustMarketcatalogs,setActiveOptions, data, lang}) => {
    console.log("data",data)
    const [cookies,setCookie] = useCookies();
    const [open,setOpen] = useState({})
    const [activeCountries,setActivecountries] = useState([])
    const [tumu,setTumu] = useState("a")
    const allData = useSelector((state) => state.general);
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState('');
    const  openLeague = (id,e,title,image) => {
        e.preventDefault();
        if (cookies.lang) {
            dispatch(getLeagues(id, "league", title, image,cookies.lang));
        }
        else {
            dispatch(getLeagues(id, "league", title, image,"en"));
        }

    }
    const openItems = (Id) => {
        if(open===Id) { setOpen(0); } else { setOpen(Id) }
    }
    const [oddtype,setOddtype] = useState(0)
    const [activeleague,setActiveleague] = useState({})
    const handleClick = (ulkeId) => {
        setOpen(prevOpen => ({
            ...prevOpen,
            [ulkeId]: !prevOpen[ulkeId]
        }));
    };
    const handleSelectChange = (data) => {
        adjustMarketcatalogs(data);
    };
    const filterDatas = () => {
        const uniqueItems = countries.data.filter((item, index, self) =>
            index === self.findIndex((t) => t.name === item.name)
        );
        setActivecountries(uniqueItems);
    }
    useEffect(()=>{
        filterDatas();

    },[countries])

    return (
        <div className={"left_nav"}>
            <div className={"bit_menu"}>

                <div className={"mt20 row"}>
                    <div>
                        <div className={"widget_title_center"}> <span>Seçenekler</span></div>
                        <React.Fragment key={100}>
                            <ListItem  button >
                                <input value={0} onChange={()=>handleSelectChange(0)} className={"options_select"} name={"options"} type={"checkbox"} />
                                <span onClick={()=>handleSelectChange(0)} className={"select_title"}> TÜMÜ</span>
                            </ListItem>
                        </React.Fragment>
                        {data && data.marketCatalogues.map((market, index) => (
                            <React.Fragment key={index}>
                                <ListItem  button >
                                    <input value={index} onChange={()=>handleSelectChange(market)} className={"options_select"} name={"options"} type={"checkbox"} />
                                    <span onClick={()=>handleSelectChange(market)} className={"select_title"}> {market.marketName}</span>
                                </ListItem>
                            </React.Fragment>

                        ))}
                    </div>
                </div>

            </div>







        </div>
    );
}
export  default DetailLeftnav;
