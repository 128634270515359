import React, {useEffect, useState} from 'react';
import {addOdd, deleteOdd} from "../../actions/general";
import {connect, useDispatch, useSelector} from "react-redux";

function Oddetail ({matchdata,handleExpandClick,mtch,index, currentAppLocale}) {
    const dispatch = useDispatch();
    const allData = useSelector((state) => state.general);
    const handleOddsClick = (runnerIndex,index, amount) => {
        // Aynı runner'a tekrar tıklanırsa kapatır


    };
    const addCoupon = (type,runner,runnerIndex,odd,oddindex,oynamadurumu,event,catalog) => {
            let home = event.name.split("v")[0];
            let away = event.name.split("v")[1];
           // if(order.Name===props.lang['1x2']) { orderName=props.lang["match_score"]; }
            let odd_detail = {
                "id":type+runner.selectionId+oddindex,
                "odd":odd.price,
                "amount":10,
                "odd_id":oddindex,
                "ext_id":0,
                "type":type,
                "event_date":event.openDate,
                "spov":0,
                "market_id":runner.selectionId,
                "odd_option":catalog.marketName,
                "option_id":oddindex,
                "first":home,
                "second":away,
                "select_side":oddindex,
                "selected":runner.runnerName
            }

            if(oynamadurumu==='active') {
                dispatch(deleteOdd(odd_detail.id,odd_detail));

            } else {
                dispatch(addOdd(odd_detail.id,odd_detail));

            }
        }



    return (
        <div className={"mb-20"}>
            {mtch.runners.slice(0, 3).map((runner, runnerIndex) => {

                return (
                    <>
                        <div className={"col-md-12"}>
                        {runnerIndex === 0 && (
                            <div  className="match-item">
                                <div className="match-header">
                                    <a className="detail-match-name">{mtch.marketName}</a>
                                </div>
                            </div>
                        )}
                        <div className={"row match-row"}>
                        <div className={"runner-name col-md-5 name-side"}>{runner.runnerName}</div>
                        <div key={runnerIndex} className="odds-side col-md-7 odds-item">
                            <>
                            {runner.ex.availableToBack.map((back,backindex) => {

                                let id = "back"+runner.selectionId+backindex;
                                let control = allData.odddata.filter(item => item.id ===id );
                                let clasname = backindex===2 ? " back ": " soft ";
                                let clasname2 =  control.length>0 ? "active": " ";

                            return (
                                <div key={backindex}
                                    className={clasname+clasname2}
                                    onClick={() => addCoupon("back",runner,runnerIndex,back,backindex,clasname2,matchdata,mtch )}
                                >
                                    {back.price}
                                </div>
                            )
                             } ) }
                            })



                                {runner.ex.availableToLay.map((lay,layindex) => {

                                    let id = "lay"+runner.selectionId+layindex;
                                    let control = allData.odddata.filter(item => item.id ===id );
                                    let clasname = layindex===0 ? " lay ": " soft ";
                                    let clasname2 =  control.length>0 ? "active": " ";
                                    return (
                                        <div key={layindex}
                                             className={clasname+clasname2}
                                             onClick={() => addCoupon("lay",runner,runnerIndex,lay,layindex,clasname2,matchdata, mtch)}
                                        >
                                            {lay.price}
                                        </div>
                                    )
                                } ) }
                                })
                            </>
                        </div>
                            </div>

                    </div>
                    </>
                )
            })}

       </div>
    )
}
const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(Oddetail);