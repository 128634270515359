import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {deleteCoupons, deleteOdd, mainCoupon, updateOdd, userBalance} from "../../actions/general";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";

const Coupon = ({setLeague, lang}) => {
    const dispatch = useDispatch();
    const allData = useSelector((state) => state.general);
    const [coupondata, setCoupondata] = useState([]);
    const [oran,setOran] = useState(0);
    const [tutar,setTutar] = useState(0)
    const [backdata,setBackdata] = useState([]);
    const [laydata,setLaydata] = useState([]);
    const [warning,setWarnnig] = useState({})
    useEffect(() => {
            setCoupondata(allData.odddata);
            let back = allData.odddata.filter(odd => odd.type === "back");
            let lay = allData.odddata.filter(odd => odd.type === "lay");
            setBackdata(back);
            setLaydata(lay)
    }, [allData]);

    const incrementBet = (odd) => {
     let newodd = (Number(odd.odd) + 0.01).toFixed(2);
     odd.odd = newodd;
     updateCoupon(odd)
     setCoupondata(allData.odddata)
    };

    const decrementBet = (odd) => {
        if (odd.odd>0) {
        let newodd = (Number(odd.odd) - 0.01).toFixed(2);
        odd.odd = newodd;
        updateCoupon(odd)
        setCoupondata(allData.odddata)

        }
    };
    const deleteOdds = (order) => {
        dispatch(deleteCoupons());
    }
    const updateCoupon = (odd) => {
      dispatch(updateOdd(odd,coupondata)).then(()=>{
          setCoupondata(allData.odddata)
      })
    }
    const changebackPrice = (e,odd) => {
        let newprice = e.target.value;
         odd.odd = newprice;
        const updatedData = backdata.map((item) =>
            item.id === odd.id ? { ...item, odd: newprice } : item
        );
        updateCoupon(odd);
        setCoupondata(allData.odddata)
        setBackdata(updatedData);

    }
    const changelayPrice = (e,odd) => {
        let newprice = e.target.value;
        odd.odd = newprice;
        const updatedData = laydata.map((item) =>
            item.id === odd.id ? { ...item, odd: newprice } : item
        );
        updateCoupon(odd);
        setCoupondata(allData.odddata)
        setLaydata(updatedData);

    }
    const changebackTutar = (e,odd) => {
        let newamount = e.target.value;
        odd.amount = Number(newamount);
        const updatedData = backdata.map((item) =>
            item.id === odd.id ? { ...item, amount: Number(newamount) } : item
        );
        updateCoupon(odd);
        setCoupondata(allData.odddata)
        setBackdata(updatedData);
    }
    const changelayTutar = (e,odd) => {
        let newamount = e.target.value;
        odd.amount = newamount;
        const updatedData = laydata.map((item) =>
            item.id === odd.id ? { ...item, amount: Number(newamount) } : item
        );
        updateCoupon(odd);
        setCoupondata(allData.odddata)
        setLaydata(updatedData);
    }
    const deleteOddata = (odd) => {
        dispatch(deleteOdd(odd.id,odd));
    }
    const saveodd = async () => {
        if(allData.userbalance) {
            if(allData.amounttoplam>0) {
                let data = {
                    "user":allData.userdata,
                    "odds":allData.odddata,
                    "tutar":allData.amounttoplam,
                    "toplam_oran":allData.oddtoplam
                }


                axios.post(
                    WEB_CONFIG.server+'/api/sport/addcoupon',
                    data,
                    { headers: { 'Content-Type': 'application/json' }}
                )
                    .then(response =>{
                        console.log(response.data);
                        axios.post(
                            WEB_CONFIG.server+'/api/sport/balance',
                            allData.userdata,
                            { headers: { 'Content-Type': 'application/json' }}
                        )
                            .then(response =>{

                                dispatch(userBalance(response.data.balance));
                                let scp = {
                                    "img":"error.png",
                                    "title":"Başarı",
                                    "desc":"Kupon oynanmıştır"
                                }
                                setWarnnig(scp);
                                setTimeout(function(){
                                    setWarnnig({})
                                    dispatch(deleteCoupons());
                                },2000)

                            })
                            .catch(error => {
                                console.log(error.data)
                            });

                    })
                    .catch(error => {
                         console.log(error.data)
                    });





            }
            else {

                let scp = {
                    "img":"error.png",
                    "title":"Hata",
                    "desc":"Lütfen Bir tutar giriniz"
                }
                setWarnnig(scp);
            }
        } else {

            let scp = {
                "img":"error.png",
                "title":"Hata",
                "desc":"Lütfen Önce Giriş Yapınız"
            }
            setWarnnig(scp);

        }

    }
    return (
        <div>
            <div className={"coupon-title"}>BAHİS</div>
            {warning && (
                <div className={"warns"}>
                    <div className={"warning_title"}>{warning.title}</div>
                    <div className={"warning_desc"}>{warning.desc}</div>
                </div>
            )}
            {backdata.length>0 ? (
                <div className={"coupon-detail"}>
                    <table className={"odd_table"}>
                        <thead>
                        <tr>
                            <th><span className={"odd-back"}>BACK</span> </th>
                            <th>Oran</th>
                            <th>Tutar</th>
                            <th>Kar</th>
                        </tr>
                        </thead>
                        <tbody>
                        {backdata.map((odd,index)=>{
                            return (
                                <tr key={index}>
                                    <td colSpan={20}>
                                        {odd.odd_option}
                                        <br />
                                        {odd.selected}
                                    </td>
                                    <td>
                                        <div className={"flex right_calc"}>
                                       <button className="calc-btn" onClick={()=> decrementBet(odd)}>-</button>
                                        <span className="bet-amount">
                                            <input value={odd.odd}  min={1} className="betamount-input" onChange={(e)=>changebackPrice(e,odd)} type={"text"}  />
                                            </span>
                                        <button className="calc-btn" onClick={()=>incrementBet(odd)}>+</button>
                                        </div>
                                    </td>
                                    <td> <input min={1} className="betamount-input" onChange={(e)=>changebackTutar(e,odd)} type={"number"} value={odd.amount} /></td>
                                    <td>{Number(odd.amount*odd.odd-odd.amount).toFixed(2)} <span onClick={()=>deleteOddata(odd)} className={"bin"}><img src={"/images/system/bin.png"} /></span></td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>
                </div>
            ):(
                <div></div>
            )}
            {laydata.length>0 ? (
                <div className={"coupon-detail"}>
                    <table className={"odd_table"}>
                        <thead>
                        <tr>
                            <th><span className={"odd-lay"}>LAY</span> </th>
                            <th>Oran</th>
                            <th>Tutar</th>
                            <th>Kar</th>
                        </tr>
                        </thead>
                        <tbody>
                        { laydata.map((odd,index)=>{
                            return (
                                <tr key={index}>
                                    <td colSpan={20}>
                                        {odd.odd_option}
                                        <br />
                                        {odd.selected}
                                    </td>
                                    <td>
                                        <div className={"flex right_calc"}>
                                            <button className="calc-btn" onClick={()=> decrementBet(odd)}>-</button>
                                            <span className="bet-amount">
                                            <input value={odd.odd} min={1} className="betamount-input" onChange={(e)=>changelayPrice(e,odd)} type={"text"}  />
                                            </span>
                                            <button className="calc-btn" onClick={()=>incrementBet(odd)}>+</button>
                                        </div>
                                    </td>
                                    <td> <input min={1} className="betamount-input" onChange={(e)=>changelayTutar(e,odd)} type={"number"} value={odd.amount} /></td>
                                    <td>{Number(odd.amount*odd.odd-odd.amount).toFixed(2)} <span onClick={()=>deleteOddata(odd)} className={"bin"}><img src={"/images/system/bin.png"} /></span></td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </table>
                </div>
            ):(
                <div></div>
            )}
            <div className={"coupon_footer"}>
                <div className={"risk"}>Kazanç: {allData.oddtoplam} </div>
                <div className={"risk"}>Risk: {allData.amounttoplam} </div>
                <div className={"footer_btns"}>
                    <button onClick={()=>deleteOdds(0)} className={"btn btn-temizle"}>Tümünü Sil</button>
                    <button type={"button"} onClick={()=>saveodd()} className={"btn btn-bahis"}>Bahis Yap</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(Coupon);