import React, {useEffect, useState} from "react";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
const Footer = (props) => {



    return (
        <div className={"home_footer"}>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <div className={"foot_logo"}>
                        <img className={"foot_logo"} src={"/images/system/logo2.png"} />
                    </div>
                    <div className={"footer_desc"}>
                        Copyright © 2023 Betexchange. All rights reserved. Betexchange is a brand name of Betexchange Gaming N.V. Company Address: Curacao. Betexchange is licensed and authorized by the Government of Curaçao and operates under the Master License of Gaming Services Provider, N.V. #365/JAZ
                        Betexchange Gaming N.V. payments can be processed by Company Address: 25, 1, MARIA
                    </div>
                </div>
                <div className={"col-md-3"}>
                    <div className={"foot_menu_title"}>ABOUT US</div>
                    <ul className={"foot_nav"}>
                        <li><a href={"#"}>About Betexchange</a></li>
                        <li><a href={"#"}>Sports Policy</a></li>
                        <li><a href={"#"}>Responsible Gaming</a></li>
                        <li><a href={"#"}>Privacy Policy</a></li>
                        <li><a href={"#"}>Terms & Conditions </a></li>
                    </ul>

                </div>
                <div className={"col-md-3"}>
                    <div className={"foot_menu_title"}>COMMUNITY</div>
                    <ul className={"foot_nav"}>
                        <li><a href={"#"}>Twitter</a></li>
                        <li><a href={"#"}>Telegram</a></li>
                        <li><a href={"#"}>Discord</a></li>
                        <li><a href={"#"}>Facebook</a></li>
                        <li><a href={"#"}>Contact Us </a></li>
                    </ul>
                </div>
                <div className={"col-md-3"}>
                    <div className={"foot_menu_title"}>ABOUT US</div>
                    <ul className={"foot_nav"}>
                        <li><a href={"#"}>About Betexchange</a></li>
                        <li><a href={"#"}>Sports Policy</a></li>
                        <li><a href={"#"}>Responsible Gaming</a></li>
                        <li><a href={"#"}>Privacy Policy</a></li>
                        <li><a href={"#"}>Terms & Conditions </a></li>
                    </ul>

                </div>
            </div>

        </div>

    );
}
export  default Footer;
