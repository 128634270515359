import * as types from "../actions/types";

const initialState = {
    datatype:null,
    anadata:[],
    extradata:[],
    userdata:[],
    locale:"en",
    teams:[],
    maincoupon: [],
    leftleagues: [],
    general:[],
    contentdata:[],
    listtype: {
        type: "general",
        title:"Latest Matches",
        image:"WRL"
    },
    orders:[],
    odddata:[],
    currentleague:{
        Result:{
           Items:{

           }
        }
    },
    changepass:{
      "success":"",
      "message":""
    },
    allleagues:[],
    sellorders:[],
    buyorders:[],
    currentOrder: null,
};

const generalReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.FILTER_DAY:
            if(action.day==='all') {

                return {
                    ...state,
                    currentleague:state.allleagues
                }
            }

            let lig=state.allleagues.Result.Items[0].Events;
            let ara = lig.filter(l => l.EventDate.indexOf(action.day) > -1 )
            return {
                ...state,
                currentleague:{
                    Result: {
                        Items:[
                            {
                                Events:ara
                            }
                        ]
                    }
                }



            }
        case types.FILTER_WORD:
            if(action.word.length===0) {

                return {
                    ...state,
                    currentleague:state.allleagues
                }
            }

            let ligs=state.allleagues.Result.Items[0].Events;
            let aras = ligs.filter(l => l.Name.toLowerCase().indexOf(action.word.toLowerCase()) > -1 )
            return {
                ...state,
                currentleague:{
                    Result: {
                        Items:[
                            {
                                Events:aras
                            }
                        ]
                    }
                }



            }
           case types.CHANGE_LANGUAGE:
            //console.log("localexxxxddddd");   console.log(action.lang);
            return {
                ...state,
                locale: action.lang,
                teams:action.teams
            };
        case types.GET_LEAGUES:

            return {
                ...state,
                currentleague:action.payload,
                listtype:action.listtype,
                allleagues:action.payload
            }

        case types.GET_LEFTLEAGUES:

            return {
                ...state,
                leftleagues:action.payload.Result[0].Items

            }
        case types.CHANGE_PASS:

            return {
                ...state,
                changepass:action.changepass

            }
        case types.CREATE:
            return {
                ...state,
                contentdata: action.payload,
            };
        case types.USER_DATA:
            return {
                ...state,
                userdata: action.payload,
            };
        case types.UPDATE:
            return {
                ...state,
                currentOrder: action.payload,
            };
        case types.USER_BALANCE:
            return {
                ...state,
                userbalance: action.userbalance
            }
        case types.USER_DETAILS:
            return {
                ...state,
                userdata: action.userdata
            }
        case types.MAIN_COUPON:
            return {
                ...state,
                maincoupon: action.payload
            }
        case types.DELETE_COUPON:
            return {
                ...state,
                odddata: [],
                oddtoplam:0
            }
        case types.DELETE_ODD:
            let sonarray =state.odddata.filter(odd => odd.id !== action.odddata);
            let fiyatd = 0;
            let totalamount = 0;
            for (let i = 0; i < sonarray.length; i++) {
                let itm = sonarray[i];
                let odd = Number(itm.odd);
                let price = Number(itm.amount);
                let total = odd * price;
                fiyatd += total;
                totalamount +=price
            }


            return {
                ...state,
                odddata: state.odddata.filter(odd => odd.id !== action.odddata),
                oddtoplam: fiyatd.toFixed(2),
                amounttoplam: totalamount.toFixed(2)

            };
        case types.UPDATE_ODD:
            let fiyat = 0;
            // Mevcut array üzerinde güncelleme yap.
            let sonar = state.odddata.map((odd) =>
                odd.id === action.odddata.id ? action.odddata : odd
            );
            let totalamounts = 0;
            // Toplam fiyatı hesapla.
            for (let i = 0; i < sonar.length; i++) {
                let itm = sonar[i];
                let odd = Number(itm.odd);
                let price = Number(itm.amount);
                let total = odd * price;
                fiyat += total;
                totalamounts +=price
            }

            return {
                ...state,
                odddata: sonar, // Güncellenmiş array
                oddtoplam: fiyat.toFixed(2),
                amounttoplam: totalamounts.toFixed(2)
            };

        case types.ADD_ODD:
            let yenidata= state.odddata.filter(odd => odd.id === action.odddata);
            let sondata= state.odddata.filter(odd => odd.id !== action.odddata);
            let totalamounta = 0;
           if(yenidata.length>0) {

               let sonarray= [...sondata,action.payload];
               let fiyats = 0;
               for (let i = 0; i < sonarray.length; i++) {
                   let item = sonarray[i];
                   let odd = Number(item.odd);
                   let price = Number(item.amount);
                   let totals = odd * price;
                   fiyats += totals;
                   totalamounta +=price
               }

               return {
                   ...state,
                   odddata: [...sondata,action.payload],
                   oddtoplam: fiyats.toFixed(2),
                   amounttoplam: totalamounta.toFixed(2)
               };
           }
           else {
               let sonarray= [...state.odddata,action.payload];
               let fiyats = 0;

               for (let i = 0; i < sonarray.length; i++) {
                   let item = sonarray[i];
                   let odd = Number(item.odd);
                   let price = Number(item.amount);
                   let totals = odd * price;
                   fiyats += totals;
                   totalamounta +=price
               }

               return {
                   ...state,
                   odddata: [...state.odddata,action.payload],
                   oddtoplam:fiyats.toFixed(2),
                   amounttoplam: totalamounta.toFixed(2)
               };
           }

        case types.UPDATE_DATAS:

            return {
                ...state,
                extradata: {
                    ...state.extradata,
                    [action.extradata]: action.payload,
                }

            };
        case types.DELETE_GENERAL:

            return {
                ...state,
                extradata: {
                    ...state.extradata,
                    [action.extradata]: action.payload,
                }

            };
        case types.LIST_DATA:

            return {
                ...state,
                contentdata: action.payload,


            };
        case types.LIST_USERS:
            return {
                ...state,
                extradata: {
                    ...state.extradata,
                    [action.datatype]: action.payload,
                }

            };
        case types.LIST_ALL:
            return {
                ...state,
                extradata: {
                    ...state.extradata,
                    [action.extradata]: action.payload,
                }

            };
        case types.LIST_ORDERS:
            return {
                ...state,
                extradata: {
                    ...state.extradata,
                    [action.extradata]: action.payload,
                }

            };
        case types.CREATE_USER:
            return {
                ...state,
                extradata: {
                    ...state.extradata,
                    [action.extradata]: action.payload,
                }

            };
        case types.DELETE_DATA:
            return {
                ...state,
                extradata: {
                    ...state.extradata,
                    [action.extradata]: action.payload,
                }

            };
        case types.DELETE:
            return {
                ...state,
                contentdata: state.contentdata.filter((post) => post._id !== action.payload),
                currentOrder: null,
            };
        default:
            return {
                ...state,
            };
    }
};

export default generalReducer;
