import React, {useEffect, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import Sportlist from "./Sportlist";
import {getLeagues, filterday, searchmatch} from "../../actions/general";
import {useDispatch} from "react-redux";
import Livesportlist from "./Livesportlist";
import {useCookies} from "react-cookie";
const Livebulletin = (props) => {
    const dispatch = useDispatch();
    const [cookies,setCookie] = useCookies();
    const [week,setWeek] = React.useState([]);
    function setOdds(day) {
        dispatch(filterday(day))
    }
    useEffect(() => {
        if (cookies.lang) {
            dispatch(getLeagues("live", "general", props.lang["latest_matches"], "WRL", "en"));
        }
        else {
            dispatch(getLeagues("live", "general", props.lang["latest_matches"], "WRL", "en"));
        }


        days();
        /*
        setInterval(function (){
          //  console.log("yenile");
            dispatch(getLeagues("live"));
        },10000)

         */
    }, []);

    const Searchmatch = (word) => {
        if(word.length>2) {

            dispatch(searchmatch(word));
        }
        if(word.length===0) {

            dispatch(searchmatch(word));
        }
    }
    function days () {
        const turkishDays = [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday"
        ];
        const currentDate = new Date();
        const weeks = [];
        let gun = {};
        for (let i = 0; i < 7; i++) {
            const nextDate = new Date();
            nextDate.setDate(currentDate.getDate() + i );
            let sond = nextDate.toISOString().split("T")[0];
            let tr = i===0?'today':turkishDays[nextDate.getDay()];
            // console.log(turkishDays[nextDate.getDay()]);
            gun = {
                "tr":tr,
                "date":sond
            }
            weeks.push(gun);
        }
        setWeek(weeks);
        //console.log(weeks);
    }
    return (
        <>
            <div className={"bulletin row"}>
                {week && (
                    week.map((w,index) => {

                            return (
                                <div key={index} onClick={() => setOdds(w.date)} className={"t_title"}>
                                    <div  >{props.lang[w.tr]}</div> <div>{w.date}</div>
                                </div>
                            )
                        }
                    ))
                }


                <div onClick={() => setOdds("all")} className={"t_title"}>
                    <div>{props.lang["all"]}</div>
                </div>

            </div>
            <div className={"bulletin_search row"}>
                <img src={"/images/icons/search.png"} />

                <input onChange={(e) => {Searchmatch(e.target.value)}} className={"b_input"} type={"text"} placeholder={props.lang["search_matches"]} />
            </div>
            <div className={"bulletin_notice row"}>
                <div className={"notice_title"} >{props.lang["upcoming_bets"]}</div>   <div className={"notice_close"}>X</div>
            </div>
            <Livesportlist lang={props.lang}/>
        </>
    );
}
export  default Livebulletin;
