import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {deleteOdd, userBalance,deleteCoupons,mainCoupon} from "../../actions/general";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import jwtDecode from "jwt-decode";
import leagues from "../../api/leagues.json";
const RightNav = ({setLeague, lang}) => {
    return (
        <div className={"right_nav"}>

            <div className={"mprc"}>
                <div className={"widget_title_center"}> <span>Hızlı Linkler</span></div>

                {leagues.data.map((lig, index) => {
                    return (
                        index<11 &&
                        <a href={"#"} key={index} onClick={()=>setLeague(lig.id,lig.name)} className={"left_sub"}>
                            <img src={"/images/sports/soccer.png"} />{lig.name}
                        </a>
                    )

                })}

            </div>
        </div>
    );
}

const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(RightNav);
