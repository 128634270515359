import React, {useEffect, useState} from 'react';
import Header from '../components/site/Header';
import {useSelector} from "react-redux";
import Match from "../components/home/Match";
import data from '../api/ornek.json';
import Leftnav from "../components/site/Leftnav";
import Footer from "../components/home/Footer";
import RightNav from "../components/site/RightNav";
import axios from "axios";
export default function Home (currentAppLocale) {
    const allData = useSelector((state) => state.general);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeleague, setActiveLeague] = useState(0);
    const [activeleaguename, setActiveLeagueName] = useState("");
    const [matchcount, setmatchcount] = useState(false);
    const [counts, setcounts] = useState(false);
    const [matches,setMatches] = useState([]);
    const [filtre,setFiltre] = useState(0);
    const [search,setSearch] = useState("")
    const [activematches,setActivematches] = useState([]);
    const setLeague = (id, name) => {
        if (id === activeleague) {
            setActiveLeague(0);
            setActiveLeagueName("");
        } else {
            setActiveLeague(id);
            setActiveLeagueName(name);
            setFiltre(1)
            setIsDrawerOpen(false);
            const amc = matches.filter(match => match.competitionName === name  );
            setActivematches(amc);

        }
        setmatchcount(true);
    };
    const setSearchmatches = (name) => {
        setSearch(name)
        const amc = matches.filter(match => match.name.toLowerCase().includes(name.toLowerCase())  );
        setActivematches(amc);
    }
    const filtreyiKaldir = async () => {
        setActivematches(matches);
        setFiltre(0)
    }
    const getMatches = async () => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_BACKEND+"/api/general/getallmatches",
                { headers: { 'Content-Type': 'application/json' } }
            );

            setMatches(response.data);
            setActivematches(response.data);
        } catch (error) {
            console.error("Error posting data:", error);
        }
    }
    useEffect(() => {
        getMatches();
    },[])
        return (
            <>
            <div className="main mains homemain">
            <Header lang={currentAppLocale} />
                <div className={"hmpg"}>
                    <div className={"row"}>
                        <div className={"col-md-2 left_hide "}>
                            <Leftnav setLeague={setLeague} lang={currentAppLocale}/>
                        </div>
                        <div className={"col-md-8 pr5 col-sm-12 col-xs-12"}>
                            <div className={"search_match"}>
                                <input value={search} onChange={(e)=>setSearchmatches(e.target.value)} type={"text"} placeholder={"Müsabaka Ara "}/>
                            </div>
                            {activematches.length>0 ? (
                                <Match setSearchmatches={setSearchmatches} filtre={filtre} filtreyiKaldir={filtreyiKaldir} setmatchcount={setmatchcount} activeleague={activeleague} data={activematches} counts={counts} />

                            ):(
                                <>
                                <div className={"no-match"}><h3>Şu an uygun maç bulunmamaktadır.</h3></div>
                                <span onClick={()=>filtreyiKaldir(0)} className={"f-kaldir"}>Filtreyi Kaldır</span>
                                </>

                            )}

                        </div>
                        <div className={"pl0 col-md-2 col-sm-6 right_hide "}>
                            <RightNav setLeague={setLeague} lang={currentAppLocale}/>
                        </div>
                    </div>
                    <Footer />
                </div>
              </div>
                </>
        );

}
