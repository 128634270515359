import {DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_DARK} from "../constants/Theme";

export const WEB_CONFIG = {
    server: process.env.REACT_APP_BACKEND,
    socketserver: "http://localhost:4002"
};
console.log("web config",WEB_CONFIG);

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_DARK,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#283142',
    headerNavColor: '#283142',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR
};
