import React, {useEffect, useState} from 'react';
import './Match.css';
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import {addOdd, deleteCoupons, deleteOdd, updateOdd, userBalance} from "../../actions/general";
import {useDispatch, useSelector} from "react-redux";

const Match = ({setSearchmatches, filtre, filtreyiKaldir, setmatchcount, activeleague, data, counts }) => {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [visibleOdds, setVisibleOdds] = useState(data[0].marketCatalogues[0].runners.slice(0, 3));
    const [activeCalculator, setActiveCalculator] = useState(null);
    const [activeRunner, setActiverunner] = useState(null);
    const [betAmount, setBetAmount] = useState(1); // Oran için başlangıç değeri
    const [profit, setProfit] = useState(0);
    const [tutar, setTutar] = useState(10);
    const [isOpen, setIsOpen] = useState(false);
    const [oddtype,setOddtype] = useState(0)
    const [matches, setMatches] = useState([]);
    const [responsedMathes, setResponsedMathes] = useState([]);
    const [selectedmarket, setSelectedmarket] = useState("Maç Sonucu");
    const [viewmode, setViewmode] = useState("Lig Görünümü");
    const [expandedMatchId, setExpandedMatchId] = useState(null);
    const [selectedodddetail, setselectedodddetail] = useState(null);
    const [count, setCount] = useState(counts);
    const [loading, setLoading] = useState(true);
    const [loadingcount, setLoadingcount] = useState(false);
    const [addvisible, setAddVisible] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState("error");
    const [statustext, setStatustext] = React.useState();
    const [warning,setWarnnig] = useState({})
    const allData = useSelector((state) => state.general);
    useEffect(() => {
        const token = localStorage.getItem("authToken");
        let par = ""
        if (activeleague) {
            par = "&competitionIds=" + activeleague
        }
        let isMounted = true;
        const fetchData = async () => {
            try {
                    const response = data.data;
                    //await apiGetFeedRequest("/api/events/listcompetitionevent?count=" + count + par, token);
                if (response) {
                    const filteredMatches = response.filter(match => {
                        return match.marketCatalogues && match.marketCatalogues.some(market => market.marketName === selectedmarket);
                    });
                    setResponsedMathes(filteredMatches);
                    if (response.length > 0) {
                        setmatchcount(true);
                    } else {
                        setmatchcount(false);
                    }
                    setLoading(false);
                    setLoadingcount(false);
                    if (response.length === count) {
                        setAddVisible(true);
                    } else {
                        setAddVisible(false);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const intervalId = setInterval(fetchData, 10000);

        fetchData();

        return () => {isMounted = false; clearInterval(intervalId);}
    }, [count]);

    const addcount = () => {
        setLoadingcount(true);
        setCount(count + 25);
    };

    useEffect(() => {
        const filteredMatches = responsedMathes.filter(match =>
            match.marketCatalogues.some(market => market.marketName === (selectedmarket || "Maç Sonucu"))
        );

        setMatches(filteredMatches);
    }, [selectedmarket, responsedMathes]);

    const handleExpandClick = (matchId, type, price, oddname, oddid, marketname, matchname, marketid) => {
        if (expandedMatchId === matchId && selectedodddetail.oddid === oddid && selectedodddetail.type === type) {
            setExpandedMatchId(null);
            setselectedodddetail(null);
        } else {
            setExpandedMatchId(matchId);
            setselectedodddetail({ matchId: matchId, type: type, price: price, oddname: oddname, marketname: marketname, matchname: matchname, oddid: oddid, marketid: marketid });
        }
    };

    function formatDayMonthYear(dateString) {
        const parts = dateString.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2].substring(0, 2);
        return `${day}.${month}.${year}`;
    }

    const groupedMatches = matches.reduce((acc, match) => {
        let date;
        if (viewmode === "Lig Görünümü") {
            date = match.competitionName;
        } else {
            date = formatDayMonthYear(match.openDate);
        }

        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(match);
        return acc;
    }, {});

    const handleClick = () => {
        setOpen(true);
    };

    const handleSelectChange = (event) => {
        const selected = event.target.value;
        setSelectedCategory(selected);
        const selectedMarket = data[0].marketCatalogues.find(
            (market) => market.marketId === selected
        );
      //  setVisibleOdds(selectedMarket.runners.slice(0, 3));
        setOddtype(selected)
    };

    const handleOddsClick = (runnerIndex,index, amount) => {
        // Aynı runner'a tekrar tıklanırsa kapatır
        setActiveCalculator(index);
        if(activeRunner===runnerIndex) {
            setIsOpen(false);
            setActiverunner(null);
            setTimeout(function(){
                setIsOpen(true);
                setActiverunner(runnerIndex);
            },400)
        }
        else {
            setIsOpen(true);
            setActiverunner(runnerIndex);

        }

        setBetAmount(amount); // Her yeni açılışta betAmount sıfırlanır
    };
    const saveodd = async () => {
        console.log(allData.odddata)
        if(allData.userbalance) {
            if(allData.amounttoplam>0) {
                let data = {
                    "user":allData.userdata,
                    "odds":allData.odddata,
                    "tutar":allData.amounttoplam,
                    "toplam_oran":allData.oddtoplam
                }
                console.log(data);


                axios.post(
                    WEB_CONFIG.server+'/api/sport/addcoupon',
                    data,
                    { headers: { 'Content-Type': 'application/json' }}
                )
                    .then(response =>{
                        console.log(response.data);
                        axios.post(
                            WEB_CONFIG.server+'/api/sport/balance',
                            allData.userdata,
                            { headers: { 'Content-Type': 'application/json' }}
                        )
                            .then(response =>{

                                dispatch(userBalance(response.data.balance));
                                let scp = {
                                    "img":"error.png",
                                    "title":"Başarı",
                                    "desc":"Kupon oynanmıştır"
                                }
                                setWarnnig(scp);
                                setTimeout(function(){
                                    setWarnnig({})
                                },3000)

                            })
                            .catch(error => {
                                console.log(error.data)
                            });

                    })
                    .catch(error => {
                        console.log(error.data)
                    });





            }
            else {

                let scp = {
                    "img":"error.png",
                    "title":"Hata",
                    "desc":"Lütfen Bir tutar giriniz"
                }
                setWarnnig(scp);
                setTimeout(function(){
                    setWarnnig({})
                },3000)
            }
        } else {

            let scp = {
                "img":"error.png",
                "title":"Hata",
                "desc":"Lütfen Önce Giriş Yapınız"
            }
            setWarnnig(scp);
            setTimeout(function(){
                setWarnnig({})
            },3000)

        }

    }

    const incrementBet = () => {
        let odd = allData.odddata[0];
        let sondata = Number(betAmount) + 0.01;
        let sonamount  = Number(sondata)*Number(tutar);
        odd.odd  = Number(sondata).toFixed(2);
        odd.amount = Number(sonamount).toFixed(2);
        dispatch(updateOdd(odd,allData.odddata))
        setBetAmount(Number(sondata).toFixed(2));
        setProfit(Number(sonamount).toFixed(2));
    };

    const decrementBet = () => {
        if (betAmount > 1) {
            let odd = allData.odddata[0];
            let sondata = Number(betAmount) - 0.01 ;
            let sonamount  = Number(sondata)*Number(tutar);
            odd.odd  = Number(sondata).toFixed(2);
            odd.amount = Number(sonamount).toFixed(2);
            dispatch(updateOdd(odd,allData.odddata))
            setBetAmount(Number(sondata).toFixed(2));
            setProfit(Number(sonamount).toFixed(2));
        }
    };
  const adjustProfit = (e) => {
      let odd = allData.odddata[0];
      setTutar(Number(e.target.value));
      let sonamount  = (Number(betAmount)*Number(e.target.value)).toFixed(2);
      odd.amount = sonamount;
      dispatch(updateOdd(odd,allData.odddata))
      setProfit(Number(sonamount).toFixed(2));
  }

    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Aylar 0'dan başlıyor, bu yüzden +1 ekliyoruz.
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
    const addCoupon = (type,runner,runnerIndex,odd,oddindex,oynamadurumu,event,catalog) => {
        let home = event.name.split("v")[0];
        let away = event.name.split("v")[1];

        let odd_detail = {
            "id":type+runner.selectionId+oddindex,
            "odd":odd.price,
            "amount":10,
            "odd_id":oddindex,
            "ext_id":0,
            "type":type,
            "event_date":event.openDate,
            "spov":0,
            "market_id":runner.selectionId,
            "odd_option":catalog.marketName,
            "option_id":oddindex,
            "first":home,
            "second":away,
            "select_side":oddindex,
            "selected":runner.runnerName
        }
        console.log(odd_detail)
        if(oynamadurumu==='active') {
            dispatch(deleteOdd(odd_detail.id,odd_detail));

        } else {
            dispatch(deleteCoupons());
            dispatch(addOdd(odd_detail.id,odd_detail));
            handleOddsClick(runnerIndex,oddindex, odd.price)

        }


    }

    return (
        <div className="container">

            <h1 className="matches-header">Sports Matches</h1>
            <select className="select-box" value={selectedCategory} onChange={handleSelectChange}>
                {data[0].marketCatalogues.map((market, index) => (
                    <option key={index} value={index}>
                        {market.marketName}
                    </option>
                ))}
            </select>
            { filtre && (
                <span onClick={()=>filtreyiKaldir(0)} className={"f-kaldir"}>Filtreyi Kaldır</span>
            )}
            {warning.title && (
                <div className={"warns home_warn"}>
                    <div className={"warning_title"}>{warning.title}</div>
                    <div className={"warning_desc"}>{warning.desc}</div>
                </div>
            )}
            <div className="match-list">
                {data ? data.map((match, index) => (
                    <div key={index}>
                    <div  className="match-item">
                        <div className="match-header">
                            <a href={"/matchdetails/"+match.id} className="match-name">{match.name}</a>
                            <span className="match-date">{match.marketStartTime}</span>
                        </div>
                        <div className={"match-date"}>
                            <span className="match-name">  {formatDate(new Date(match.openDate))}</span>
                        </div>
                        <div className={"match-date"}>
                            <span className="eslesmis">  Eşleşmiş: {match.marketCatalogues[oddtype].totalMatched}</span>
                        </div>
                        { match.marketCatalogues[oddtype].status === "OPEN" ? (
                            <div className="odds-container">
                                { match.marketCatalogues[oddtype].runners.slice(0, 3).map((runner, runnerIndex) => {
                                    let id = "back"+runner.selectionId+index;
                                    let id2 = "lay"+runner.selectionId+index;
                                    let control = allData.odddata.filter(item => item.id ===id );
                                    let control2 = allData.odddata.filter(item => item.id ===id2 );
                                    let classname = " back ";
                                    let classnamelay = " lay ";
                                    let classname2 =  control.length>0 ? "active": " ";
                                    let classname2lay =  control2.length>0 ? "active": " ";


                                    return (

                                        <div key={runnerIndex} className="odds-item">
                                            <div
                                                className={classname+classname2}
                                                onClick={() => addCoupon("back",runner,runnerIndex,runner.ex.availableToBack[0],index,classname2, match, match.marketCatalogues[oddtype])}
                                            >
                                                {runner.ex.availableToBack[0] && runner.ex.availableToBack[0].price}
                                            </div>
                                            <div
                                                className={classnamelay+classname2lay}
                                                onClick={() => addCoupon("lay",runner,runnerIndex,runner.ex.availableToLay[0],index,classname2, match, match.marketCatalogues[oddtype])}
                                            >
                                                {runner.ex.availableToLay[0] && runner.ex.availableToLay[0].price}
                                            </div>


                                        </div>
                                    )
                                }  )}
                            </div>
                        ):(
                            <div className="odds-container">
                              <div className={"y_hacim"}> Yetersiz Hacim! Min Hacim: 1000</div>
                            </div>
                        ) }

                        {/* Hesaplayıcı kutusu */}

                    </div>
                    <div className={"bt-slip"}>

                {index===activeCalculator && isOpen && (
                    <div className="calculator">
                    <button className="calc-btn" onClick={()=> decrementBet()}>-</button>
                    <span className="bet-amount">
                        <input min={1} className="betamount-input" onChange={(e)=>setBetAmount(e.target.value)} type={"text"} value={betAmount} />
                    </span>
                    <button className="calc-btn" onClick={()=>incrementBet()}>+</button>
                    <input value={tutar} onChange={(e)=>adjustProfit(e)} type="text" className="bet-input" placeholder="Tutar" />
                    <button onClick={()=>saveodd()} className="place-bet">
                        BAHİS
                        {profit && profit>0 ? (
                            <div className={"profit"}>Profit: {profit}</div>
                        ):(<></>)}

                    </button>
                    <button className="cancel-bet" onClick={() => setActiveCalculator(null)}>CANCEL</button>
                    </div>
                    )}
                    </div>
                    </div>
                )):(
                    <h3>Loading...</h3>
                )}
            </div>
        </div>
    );
};


export default Match;
