import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addOdd, deleteOdd} from "../../actions/general";

export default function Oddetailothers ({matchdata, mtch,index, currentAppLocale}) {
    const dispatch = useDispatch();
    const allData = useSelector((state) => state.general);
    const handleOddsClick = (runnerIndex,index, amount) => {
        // Aynı runner'a tekrar tıklanırsa kapatır


    };
    const addCoupon = (type,runner,runnerIndex,odd,oddindex,oynamadurumu,event,catalog) => {
        let home = event.name.split("v")[0];
        let away = event.name.split("v")[1];
        // if(order.Name===props.lang['1x2']) { orderName=props.lang["match_score"]; }
        let odd_detail = {
            "id":type+runner.selectionId+oddindex,
            "odd":odd.price,
            "amount":10,
            "odd_id":oddindex,
            "ext_id":0,
            "type":type,
            "event_date":event.openDate,
            "spov":0,
            "market_id":runner.selectionId,
            "odd_option":catalog.marketName,
            "option_id":oddindex,
            "first":home,
            "second":away,
            "select_side":oddindex,
            "selected":runner.runnerName
        }

        if(oynamadurumu==='active') {
            dispatch(deleteOdd(odd_detail.id,odd_detail));

        } else {
            dispatch(addOdd(odd_detail.id,odd_detail));

        }
    }


    return (
        <>
            {mtch.runners.slice(0, 3).map((runner, runnerIndex) => {
                let id = "back"+runner.selectionId+"0";
                let control = allData.odddata.filter(item => item.id ===id );
                let clasname2 =  control.length>0 ? "active": " ";

                let layid = "lay"+runner.selectionId+"0";
                let control2 = allData.odddata.filter(item => item.id ===layid );
                let clasname3 =  control2.length>0 ? "active": " ";
                return (
                    <>
                        <div className={"col-md-12"}>
                            {runnerIndex === 0 && (
                                <div  className="match-item">
                                    <div className="match-header">
                                        <a className="detail-match-name">{mtch.marketName}</a>
                                    </div>
                                </div>
                            )}
                            <div className={"row match-row"}>
                                <div className={"runner-name col-md-5 name-side"}>{runner.runnerName}</div>
                                <div key={runnerIndex} className="odds-side col-md-7 odds-item">
                                    <div
                                         className={"back "+clasname2}
                                         onClick={() => addCoupon("back",runner,runnerIndex,runner.ex.availableToBack[0],0,clasname2,matchdata,mtch )}
                                    >
                                        {runner.ex.availableToBack[0] && runner.ex.availableToBack[0].price}
                                    </div>


                                    <div
                                        className={"lay "+clasname3}
                                        onClick={() => addCoupon("lay",runner,runnerIndex,runner.ex.availableToLay[0],0,clasname3,matchdata,mtch )}
                                    >
                                        {runner.ex.availableToLay[0] && runner.ex.availableToLay[0].price}
                                    </div>
                            </div>

                        </div>
                        </div>
                    </>
                )
            })}

        </>
    )
}