import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {deleteOdd, userBalance,deleteCoupons,mainCoupon} from "../../actions/general";
import axios from "axios";
import {WEB_CONFIG} from "../../config/Static";
import jwtDecode from "jwt-decode";
import {setAuthorizationToken} from "../../helpers/setAuthorizationToken";
import {Link} from "react-router-dom";
import Ads from "./Ads";
import Slider from "react-slick";

const Slip = (props) => {
    const allData = useSelector((state) => state.general);
    const dispatch = useDispatch();
    const [warn, setWarn] = useState(false);
    const [oddata, setOddata] = useState([]);
    const [tutar, setTutar] = useState(0);
    const [player, setPlayer] = useState(null);
    const [cpp,setCpp] = useState([])
    const [maincoupon,setMaincoupon] = useState([])
    const [mainbets,setMainbets] = useState([])
    const [cp, setCp] = useState({
        "img":"",
        "title":"",
        "desc":""
    });
    useEffect(() => {
        setOddata(allData.odddata);
        Coupons();
    }, [oddata]);
    const Coupons = async () => {
        if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {
            const jwt_Token_decoded = await jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
            axios.post(
                WEB_CONFIG.server + '/api/sport/dailycoupons',
                jwt_Token_decoded,
                {headers: {'Content-Type': 'application/json'}}
            )
                .then(response => {

                    setCpp(response.data);
                })
                .catch(error => {
                    console.log(error.data)
                });


        }

    }
    const deleteOdds = (order) => {
        dispatch(deleteOdd(order,allData.odddata[order]));
    }
    const deleteCoupon = () => {

        dispatch(deleteCoupons());
        setWarn(false);
    }
    const renderItem = (odd,index) => {

        const oynamakontrol= allData.odddata.filter(odds => odds.id ===odd.id);
        let oynanmis="passive";
        if(oynamakontrol.length>0) {  oynanmis='active'; }
        return (
            <div key={odd.id} className={"slip_match"}>
                <div onClick={() => deleteOdds(odd.id)} className={"slip_cancel "+oynanmis}><img src={"/images/icons/cancel.png"} /></div>
                <div className={"slip_team"}>{odd.first} - {odd.second}</div>
                <div className={"slip_odd"}>{props.lang["bet_odd"]}</div>
                <div className={"slip_result"}>{odd.selected} <span className={"right_align"}>{odd.odd}</span></div>
            </div>
        );
    }
    const saveodd = async () => {
      if(allData.userbalance) {
        if(tutar>0) {
            let data = {
                "user":allData.userdata,
                "odds":allData.odddata,
                "tutar":tutar,
                "toplam_oran":allData.oddtoplam
            }


            axios.post(
                WEB_CONFIG.server+'/api/sport/addcoupon',
                data,
                { headers: { 'Content-Type': 'application/json' }}
            )
                .then(response =>{
                    console.log(response.data);
                    setCp(response.data[1]);
                    dispatch(mainCoupon(response.data[0]))

                    axios.post(
                        WEB_CONFIG.server+'/api/sport/balance',
                        allData.userdata,
                        { headers: { 'Content-Type': 'application/json' }}
                    )
                        .then(response =>{

                            dispatch(userBalance(response.data.balance));
                            setWarn(true);
                        })
                        .catch(error => {
                            console.log(error.data)
                        });
                    setTimeout(function(){
                        setCp({
                            "img":"",
                            "title":"",
                            "desc":""
                        });
                    },3000);
                })
                .catch(error => {
                    // console.log(error.data)
                });





        }
        else {

            let scp = {
                "img":"error.png",
                "title":"Hata",
                "desc":"Lütfen Bir tutar giriniz"
            }
            setCp(scp);
            setTimeout(function(){
                setCp({
                    "img":"",
                    "title":"",
                    "desc":""
                });
            },3000);
        }
      } else {

        let scp = {
            "img":"error.png",
            "title":"Hata",
            "desc":"Lütfen Önce Giriş Yapınız"
        }
        setCp(scp);
        setTimeout(function(){
            setCp({
                "img":"",
                "title":"",
                "desc":""
            });
        },3000);
      }

    }
    const maincouponset = async (item) => {
        let detail = {
            "id":item.coupon_id
        }
        await   axios.post(
            WEB_CONFIG.server+'/api/sport/coupondetails',
            detail,
            { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response =>{

                setMainbets(response.data[1]);
                setMaincoupon(response.data[0]);
            })
            .catch(error => {
                console.log(error.data)
            });

    }

    let coins = [
        {
            id:1,
            type:"BTC",
            price:"25.254.20",
            status:"high"
        },
        {
            id:2,
            type:"ETH",
            price:"1.593.12",
            status:"low"
        },
        {
            id:3,
            type:"BNB",
            price:"212.85",
            status:"high"
        },
        {
            id:4,
            type:"APT",
            price:"5.29",
            status:"high"
        },
        {
            id:5,
            type:"BCH",
            price:"216.254",
            status:"low"
        },
        {
            id:6,
            type:"ADA",
            price:"0.25",
            status:"high"
        },
        {
            id:7,
            type:"APE",
            price:"1.1189",
            status:"low"
        }
        ,
        {
            id:8,
            type:"ARB",
            price:"0.8295",
            status:"high"
        }
        ,
        {
            id:9,
            type:"DOGE",
            price:"0.0606",
            status:"high"
        }
        ,
        {
            id:10,
            type:"LINK",
            price:"7.338",
            status:"low"
        }
        ,
        {
            id:11,
            type:"LTC",
            price:"63.884",
            status:"low"
        }
        ,
        {
            id:12,
            type:"TRX",
            price:"0.084",
            status:"high"
        }
        ,
        {
            id:13,
            type:"UNIBOT",
            price:"54.883",
            status:"high"
        }
    ]
    return (
        <div className={"right_nav"}>

            <div className={"mprc"}>
                <div className={"widget_title_center"}> <span>Hızlı Linkler</span></div>

                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Irish Premiership</a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />South African Premier D</a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Austrian Erste Liga</a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Dutch Eerste Divisie</a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Australian A-League </a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Brazilian Serie A</a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Bulgarian A League</a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Algerian Ligue 1</a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Croatian 1 HNL</a>
                <a className={"left_sub"} href={"/sports"}><img src={"/images/sports/soccer.png"} />Bosnian Premier League</a>

            </div>
        </div>
    );
}

const mapStateToProps = ({ theme }) => {
    return theme;
};
export default connect(mapStateToProps, {  })(Slip);
