import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLeagues} from "../../actions/general";
import {useCookies} from "react-cookie";
import leagues from '../../api/leagues.json';
import countries from '../../api/countries.json';
import {Collapse, List, ListItem, Typography} from "@material-ui/core";
const Leftnav = ({setLeague, lang}) => {
    const [cookies,setCookie] = useCookies();
   const [open,setOpen] = useState({})
    const [activeCountries,setActivecountries] = useState([])
    const [tumu,setTumu] = useState("a")
    const allData = useSelector((state) => state.general);
   const dispatch = useDispatch();
   const  openLeague = (id,e,title,image) => {
       e.preventDefault();
       if (cookies.lang) {
           dispatch(getLeagues(id, "league", title, image,cookies.lang));
       }
       else {
           dispatch(getLeagues(id, "league", title, image,"en"));
       }

   }
    const openItems = (Id) => {
        if(open===Id) { setOpen(0); } else { setOpen(Id) }
    }

    const [activeleague,setActiveleague] = useState({})
    const handleClick = (ulkeId) => {
        setOpen(prevOpen => ({
            ...prevOpen,
            [ulkeId]: !prevOpen[ulkeId]
        }));
    };
    function filterCountries (e) {

       let sondata = countries.data.filter(country => country.name.toLowerCase().includes(e.target.value.toLowerCase()))
        const uniqueItems = sondata.filter((item, index, self) =>
            index === self.findIndex((t) => t.name === item.name)
        );
        setActivecountries(uniqueItems);
    }
    const filterDatas = () => {
        const uniqueItems = countries.data.filter((item, index, self) =>
            index === self.findIndex((t) => t.name === item.name)
        );
        setActivecountries(uniqueItems);
    }
    useEffect(()=>{
        filterDatas();

    },[countries])

    return (
        <div className={"left_nav"}>
            <div className={"bit_menu"}>

                <div className={"mt20 row"}>
                    <div className={"nav-btns col-md-12 flex"}>
                      <a onClick={()=> setTumu("a")} className={tumu==="a" && "active"} href={"#"}> TÜMÜ</a>
                      <a onClick={()=> setTumu("b")} className={tumu==="b" && "active"} href={"#"}>EN İYİ</a>
                    </div>
                </div>

            </div>

            { tumu==="a" ? (
                <>
                    <div className={"widget_title_center"}> <span>Tüm Ülkeler</span></div>
                    <input placeholder={"Ülke Ara"} className={"f-countries"} onChange={(e)=>filterCountries(e)} type={"text"} />

                {activeCountries && activeCountries.map((ulke, index) => {

                        const filteredLigler = leagues.data.filter((lig) => lig.competitionRegion === ulke.id);
                        return (

                            <div key={index}>
                <span  onClick={() => handleClick(ulke.id)} className={"left_sub"}>
                <img src={"/images/sports/soccer.png"}/>{ulke.name}
                </span>
                                <Collapse in={open[ulke.id]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {filteredLigler.map((filteredLig, index) => (
                                            <React.Fragment key={filteredLig.id}>
                                                <ListItem button onClick={() => setLeague(filteredLig.id,filteredLig.name)} sx={{ padding: "0px", backgroundColor: filteredLig.id === activeleague ? "#ff6c00" : "#3d3d3d", color: filteredLig.id == activeleague ? "#fff" : "#838282", borderBottom: "solid 1px #626262" }}>
                                                    <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>{filteredLig.name}</Typography>
                                                </ListItem>
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        )
                    })}
                )}
                </>
            ):(
                <>
                <div className={"widget_title_center"}> <span>En İyi Ligler</span></div>
            {leagues.data.map((lig, index) => {
                return (
                index<11 &&
                <a href={"#"} key={index} onClick={()=>setLeague(lig.id,lig.name)} className={"left_sub"}><img src={"/images/sports/soccer.png"} />{lig.name}</a>
                )

            })}


                <div className={"widget_title_center"}> <span>En İyi Ülkeler</span></div>

            {activeCountries && activeCountries.map((ulke, index) => {

                const filteredLigler = leagues.data.filter((lig) => lig.competitionRegion === ulke.id);
                return (
                index <20 &&
                <div key={index}>
                <span  onClick={() => handleClick(ulke.id)} className={"left_sub"}>
                <img src={"/images/sports/soccer.png"}/>{ulke.name}
                </span>
                <Collapse in={open[ulke.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
            {filteredLigler.map((filteredLig, index) => (
                <React.Fragment key={filteredLig.id}>
                <ListItem button onClick={() => setLeague(filteredLig.id,filteredLig.name)} sx={{ padding: "0px", backgroundColor: filteredLig.id === activeleague ? "#ff6c00" : "#3d3d3d", color: filteredLig.id == activeleague ? "#fff" : "#838282", borderBottom: "solid 1px #626262" }}>
                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>{filteredLig.name}</Typography>
                </ListItem>
                </React.Fragment>
                ))}
                </List>
                </Collapse>
                </div>
                )
            })}
                </>
            )}





        </div>
    );
}
export  default Leftnav;
